import { Breadcrumbs } from '@material-ui/core';
import React, { useState } from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import UpdatedInfo from '../../components/templates/UpdatedInfo';
import { searchByString } from '../../utils/functions';
import { useGlobalStyles } from '../../utils/styles';
import { LIST_COMITE } from '../../utils/transparencia-difusion/constants';
import { IListDocs } from '../../utils/transparencia-difusion/interfaces';
import { useStyles } from '../../utils/transparencia-difusion/styles';
import ListComite from './list-comite';

const Comites = () => {
    const classes = useStyles();

    const firstValue: {name:string, description:string} = {name:'Comités y Comisiones Institucionales', description:'Apartados de Comités y Comisiones Institucionales, del Instituto de Seguridad Social del Estado de Tabasco:'};
    const globalClasses = useGlobalStyles();
    const [arrayComite, setArrayComite] = useState<IListDocs[]>(LIST_COMITE);
    const [arrayBreadCrumbs, setArrayBreadCrumbs] = useState<string[]>(['Comités y Comisiones Institucionales']);
    const [info, setInfo] = useState<{name:string, description:string}>(firstValue);

    const handleClick = (name)=>{
      setArrayBreadCrumbs([...arrayBreadCrumbs, name]);
    }

    const handleClickBread = (name)=>{
      
      if(arrayBreadCrumbs.findIndex(nameI => nameI === name) === arrayBreadCrumbs.length-1){
      }else if(name == arrayBreadCrumbs[0]){
        const tempBread = arrayBreadCrumbs;
        setInfo(firstValue);
        setArrayBreadCrumbs([tempBread[0]])
        setArrayComite(LIST_COMITE);
      } else {
        
        let tempBread = [...arrayBreadCrumbs];
        
        tempBread.splice(tempBread.findIndex(e => e == name)+1, tempBread.length-1)

        let tempBreadCopy = [...tempBread];

        setArrayBreadCrumbs(tempBreadCopy);
        tempBread.shift();

        const newArrayComite = searchByString(tempBread, LIST_COMITE);

        setInfo({name: newArrayComite?.name || '', description: newArrayComite?.description || ''});
        
        setArrayComite(newArrayComite?.elements || []);
      }
    }

    return (
      <Layout>
        <SEO title='Comités y Comisiones Institucionales' />

        <div className={globalClasses.container}>
            <Breadcrumbs>
              {
                arrayBreadCrumbs.map((element, key) => (
                    <p key={key} onClick={()=>handleClickBread(element)} className={classes.linkText}>{element}</p>
                ))
              }
            </Breadcrumbs>

            <ListComite 
              onClick={(name)=> handleClick(name)} 
              setInfo={setInfo} name={info.name} 
              arrayComite={arrayComite} 
              setArrayComite={setArrayComite} 
              description={info.description}
              />
          
          <div className={globalClasses.container}>
            <UpdatedInfo fuente='Dirección de Planeación, Programación, Innovación y Evaluación' updatedAt='10 de julio de 2024' />
          </div>
        </div>
      </Layout>
    );
  };
  
  export default Comites; 